import { Fragment, useState, useMemo } from 'react'
import {
  Typography,
  Button,
  ToggleButton,
  Paper,
  Grid,
  Container,
  Box,
  Stack,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import moment from 'moment-timezone'
import clsx from 'clsx'
import { useStyles } from '../common/style'
import {
  BloodPressureEChart,
  HeartRateEChart,
} from '../PatientData/BloodPressure/BloodPressureCharts'
import { BloodPressureGrid } from '../PatientData/BloodPressure/BloodPressureGrid'
import {
  ResponsiveDurationButtons,
  useDurationDays,
} from '../PatientData/ResponsiveDurationButtons'
import { InfoDropdown } from '../PatientData/Dropdown'
import { parseDeviceMeasures } from './parseDeviceMeasures'
import { InfoGrid } from '../PatientData/InfoGrid'
import { StyledToggleButtonGroup } from '../PatientData/ToggleButtonGroup'
import { ChartPaper } from '../PatientData/ChartPaper'
import { useResponseState } from '../../common/useResponsiveState'

const lastReadingDate = (ts, timeZone) => {
  const lastReading = moment(ts)
  if (!ts || !lastReading?.isValid()) {
    return ''
  }
  const timezone =
    timeZone && timeZone !== 'local' ? timeZone : moment.tz.guess()
  if (lastReading.isBefore(moment().subtract(3, 'd'))) {
    return lastReading.tz(timezone).format('MM/DD/YY h:mm A')
  } else if (lastReading.isBefore(moment().subtract(2, 'd'))) {
    return 'Two Days Ago'
  } else if (lastReading.isBefore(moment().subtract(1, 'd'))) {
    return 'Yesterday'
  } else {
    return 'Today'
  }
}

export const DeviceReadingsDisplay = ({ imei, deviceData }) => {
  const [tableView, setTableView] = useState('Chart')
  const { days, durationText, setDurationText, setDays } = useDurationDays()

  const classes = useStyles()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)
  const timeframe = useMemo(
    () => moment().subtract(days, 'days').toDate(),
    [days]
  )

  const theme = useTheme()
  const { isMobile } = useResponseState()

  const {
    arrayBP,
    bpmTableArray,
    avgSys,
    avgDia,
    timeZone,
    deviceInfo: { sn, iccid, dataType, modelNumber, deviceId },
    lastReading,
  } = useMemo(
    () => parseDeviceMeasures(deviceData, timeframe),
    [deviceData, timeframe]
  )

  const lastMeasurementInfo = lastReading.ts
    ? {
        info: `${lastReading.systolic}/${lastReading.diastolic}`,
        title: 'Last Measurement',
        subText: lastReadingDate(lastReading.ts, timeZone),
      }
    : {
        info: '',
        title: '',
        subText: 'No measurements have been recorded yet',
      }

  const handleTableView = (_e, newTableView) => {
    if (newTableView) {
      setTableView(newTableView)
    }
  }

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '10px',
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Blood Pressure Measurements</Typography>
          <InfoDropdown
            items={[
              ...(imei ? [`IMEI: ${imei}`] : []),
              ...(sn ? [`Serial Number: ${sn}`] : []),
              ...(iccid ? [`ICCID: ${iccid}`] : []),
              ...(dataType ? [`Data Type: ${dataType}`] : []),
              ...(modelNumber ? [`Model Number: ${modelNumber}`] : []),
              ...(deviceId ? [`Device Id: ${deviceId}`] : []),
            ]}
            isMobile={isMobile}
          />
        </div>
        <Grid container spacing={1} marginBottom={theme.spacing(1)}>
          <InfoGrid
            info={lastMeasurementInfo.info}
            title={lastMeasurementInfo.title}
            subText={lastMeasurementInfo.subText}
            isMobile={isMobile}
          />
          <InfoGrid
            info={`${avgSys}/${avgDia}`}
            title={'Avg BP'}
            subText={durationText}
            isMobile={isMobile}
          />
        </Grid>
        <Box
          sx={{
            ...(!isMobile && {
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }),
          }}
        >
          {!isMobile && (
            <StyledToggleButtonGroup
              color="primary"
              value={tableView}
              exclusive
              onChange={handleTableView}
              sx={{ marginTop: theme.spacing(1), maxHeight: '70px' }}
            >
              <ToggleButton value="Chart">Chart</ToggleButton>
              <ToggleButton value="Readings">Readings</ToggleButton>
            </StyledToggleButtonGroup>
          )}
          <ResponsiveDurationButtons
            days={days}
            setDays={setDays}
            setDurationText={setDurationText}
            isMobile={isMobile}
          />
        </Box>
        {tableView === 'Chart' ? (
          <Stack
            direction="column"
            spacing={4}
            sx={{ marginTop: theme.spacing(2) }}
          >
            <ChartPaper>
              <BloodPressureEChart timeZone={timeZone} chartData={arrayBP} />
            </ChartPaper>
            <ChartPaper>
              <HeartRateEChart timeZone={timeZone} chartData={arrayBP} />
            </ChartPaper>
          </Stack>
        ) : (
          <Stack>
            <Paper sx={{ minHeight: 240, padding: theme.spacing(2) }}>
              <BloodPressureGrid gridData={bpmTableArray} />
            </Paper>
          </Stack>
        )}
      </Container>
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'whitesmoke',
          }}
        >
          <Button
            onClick={() => setTableView('Chart')}
            variant={tableView === 'Chart' ? 'contained' : 'outlined'}
            color="primary"
            size="large"
            sx={{ margin: theme.spacing(1), width: '45%' }}
          >
            Chart
          </Button>
          <Button
            onClick={() => setTableView('Readings')}
            variant={tableView === 'Readings' ? 'contained' : 'outlined'}
            size="large"
            sx={{ margin: theme.spacing(1), width: '45%' }}
          >
            Readings
          </Button>
        </Box>
      )}
    </Fragment>
  )
}
