import { Fragment } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { Skeleton } from '@mui/material'
import { PatientTextField } from './PatientTextField'
import { useStyles } from '../components/common/style'
import { CARDIOWELL, TRANSTEK, WITHINGS } from '../common/manufacters'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'

export const PatientForm = ({
  addClinic,
  timezoneRequired,
  clinics,
  isLoading,
  showWithings,
}) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()
  const classes = useStyles()

  const selectedBpDevice = watch('selectedBpDevice')
  const selectedWeightDevice = watch('selectedWeightDevice')
  const { options: timeZoneOptions } = useTimezoneSelect({
    labelStyle: 'abbrev',
    allTimezones,
  })

  return (
    <Fragment>
      {isLoading ? (
        <Fragment>
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
          <Skeleton variant="rounded" width="100%" height={56} sx={{ mt: 2 }} />
        </Fragment>
      ) : (
        <Fragment>
          <PatientTextField
            name="firstName"
            label="First Name"
            rules={{ required: 'Required field' }}
          />
          <PatientTextField name="lastName" label="Last Name" />
          <PatientTextField name="mrn" label="Medical Record #" />
          <PatientTextField name="phoneNumber" label="Phone Number" />
          <PatientTextField name="cellNumber" label="Cell Phone Number" />
          <PatientTextField name="email" label="Email Address" />
          <Controller
            name="selectedBpDevice"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                className={classes.formControl}
                required
                style={{ marginLeft: '15px', marginRight: '0px', width: '45%' }}
              >
                <br></br>
                <InputLabel id="bp-device-select-label">
                  Blood Pressure Device
                </InputLabel>
                <Select
                  labelId="bp-device-select-label-id"
                  id="bp-device-select-id"
                  value={value}
                  name={name}
                  onChange={onChange}
                >
                  <MenuItem value={CARDIOWELL}>Cardiowell</MenuItem>
                  <MenuItem value={TRANSTEK}>TT Tele RPM</MenuItem>
                  {showWithings && (
                    <MenuItem value={WITHINGS}>Withings</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
          {selectedBpDevice === CARDIOWELL && (
            <PatientTextField name="bpImei" label="Blood Pressure IMEI" />
          )}
          {selectedBpDevice === TRANSTEK && (
            <PatientTextField
              name="transtekBpImei"
              label="Blood Pressure IMEI"
            />
          )}
          {selectedBpDevice === WITHINGS && (
            <PatientTextField
              name="withingsBpDevice"
              label="Blood Pressure IMEI"
              rules={{ disabled: true }}
              disabled={true}
            />
          )}
          <Controller
            name="selectedWeightDevice"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                className={classes.formControl}
                required
                style={{ marginLeft: '15px', marginRight: '0px', width: '45%' }}
              >
                <br></br>
                <InputLabel id="ws-device-select-label">
                  Weight Device
                </InputLabel>
                <Select
                  labelId="ws-device-select-label-id"
                  id="ws-device-select-id"
                  name={name}
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem value={CARDIOWELL}>Cardiowell</MenuItem>
                  <MenuItem value={TRANSTEK}>TT Tele RPM</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {selectedWeightDevice === CARDIOWELL && (
            <PatientTextField name="weightImei" label="Weight IMEI" />
          )}
          {selectedWeightDevice === TRANSTEK && (
            <PatientTextField name="transtekWeightImei" label="Weight IMEI" />
          )}
          <PatientTextField name="pulseImei" label="Pulse Oximeter IMEI" />
          <PatientTextField name="glucoseImei" label="Glucose IMEI" />
          <PatientTextField name="address" label="Address" />
          <PatientTextField name="city" label="City" />
          <PatientTextField name="state" label="State (abbreviated)" />
          <PatientTextField name="zip" label="Zip" />
          <Controller
            name="timezone"
            control={control}
            rules={{ required: 'Required field' }}
            render={({ field: { name, value, onChange } }) => (
              <FormControl
                className={classes.formControl}
                style={{ marginLeft: '15px', marginRight: '0px', width: '45%' }}
                error={errors['timezone']}
              >
                <br></br>
                <InputLabel id="timezone-input-label">Time Zone</InputLabel>
                <Select
                  labelId="timezone-select-label"
                  id="timezone-select"
                  name={name}
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem
                    disabled={timezoneRequired ? true : false}
                    value="local"
                  >
                    Use Local Time
                  </MenuItem>
                  {timeZoneOptions.map(({ label, value }, i) => (
                    <MenuItem key={i} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
                {errors['timezone'] && (
                  <FormHelperText>{errors['timezone'].message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          {addClinic && (
            <Controller
              name="clinic"
              control={control}
              rules={{ required: 'Required field' }}
              render={({ field: { name, value, onChange } }) => (
                <FormControl
                  className={classes.formControl}
                  style={{
                    marginLeft: '15px',
                    marginRight: '0px',
                    width: '45%',
                  }}
                  error={errors['clinic']}
                >
                  <br></br>
                  <InputLabel id="clinic-input-label">Clinic</InputLabel>
                  <Select
                    labelId="clinic-select-label"
                    id="clinic-select"
                    onChange={onChange}
                    value={value}
                    name={name}
                  >
                    {clinics.map((clinic) => (
                      <MenuItem value={clinic.name} key={clinic.name}>
                        {clinic.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors['clinic'] && (
                    <FormHelperText>{errors['clinic'].message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          )}
          <Controller
            name="deviceNotificationsEnabled"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                className={classes.formControl}
                name={name}
                style={{ marginLeft: '15px', marginTop: '30px' }}
                control={
                  <Switch
                    checked={value}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={'SMS Consent'}
              />
            )}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
