import { CARDIOWELL } from '../common/manufacters'

export const defaultFormState = {
  firstName: '',
  lastName: '',
  mrn: '',
  email: '',
  phoneNumber: '',
  cellNumber: '',
  city: '',
  state: '',
  address: '',
  zip: '',
  timezone: '',
  bpImei: '',
  transtekBpImei: '',
  weightImei: '',
  transtekWeightImei: '',
  glucoseImei: '',
  pulseImei: '',
  clinic: '',
  selectedBpDevice: CARDIOWELL,
  selectedWeightDevice: CARDIOWELL,
  deviceNotificationsEnabled: false,
}
