import EChartsReact from 'echarts-for-react'
import { blue } from '../../common/colors'
import { useResponseState } from '../../common/useResponsiveState'

export const EchartsLine = ({ title, legend, xAxis, yAxis, lines }) => {
  const { isMobile } = useResponseState()
  const series = lines.map((line) => {
    return {
      type: 'line',
      symbol: 'circle',
      smooth: true,
      lineStyle: {
        width: 1.3,
        color: blue,
      },
      label: {
        show: true,
        position: 'top',
        textStyle: {
          fontSize: 10,
        },
      },
      labelLayout: {
        hideOverlap: true,
      },
      ...line,
    }
  })

  const mobileLegend = {
    orient: 'vertical',
    itemGap: 40,
    icon: 'none',
    right: 0,
    top: 110,
  }

  const option = {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      ...(isMobile && mobileLegend),
      ...legend,
    },
    xAxis,
    yAxis,
    series,
  }

  return <EChartsReact option={option} />
}
