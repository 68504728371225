import { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const InfoDropdown = ({ items = [], isMobile }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="info-dropdown"
        size="large"
        onClick={handleClick}
        color="black"
      >
        <ExpandMoreIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="info-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {items.map((item, index) => (
          <MenuItem style={{ backgroundColor: 'white' }} key={index}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
