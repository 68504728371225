import { useMemo } from 'react'
import moment from 'moment-timezone'
import { MultiLineChart } from '../../common/MultiLineChart.jsx'
import { ThresholdDot } from '../../common/ThresholdDot.jsx'
import { StageDot } from '../../common/StageDot.jsx'
import { blue, green } from '../../../common/colors.js'
import { useResponseState } from '../../../common/useResponsiveState.js'
import { EchartsLine } from '../../common/EChartsLine.jsx'

export const BloodPressureChart = ({ timeZone, threshold, chartData = [] }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = ''
          if (moment(data.ts).tz(timeZone)) {
            taken = moment(data.ts).tz(timeZone).format('MM/DD/YY')
          }
          return {
            date: taken,
            Systolic: data.systolic,
            Diastolic: data.diastolic,
          }
        })
        .reverse(),
    [chartData, timeZone]
  )
  return (
    <MultiLineChart
      title={'BP Trend'}
      label={'mmHg'}
      lines={[
        {
          key: 'Systolic',
          color: blue,
          dot: () => <StageDot threshold={threshold?.systolic} />,
        },
        {
          key: 'Diastolic',
          color: green,
          dot: () => <StageDot threshold={threshold?.diastolic} />,
        },
      ]}
      data={data}
      domain={undefined}
    />
  )
}

export const BloodPressureEChart = ({ timeZone, chartData = [] }) => {
  const { isMobile } = useResponseState()
  const { lines, dates } = useMemo(() => {
    const data = chartData
      .map((data) => {
        let taken = ''
        if (moment(data.ts).tz(timeZone)) {
          taken = moment(data.ts).tz(timeZone).format('MM/DD/YY')
        }
        return {
          systolic: data.systolic,
          diastolic: data.diastolic,
          date: taken,
        }
      })
      .reverse()
    const lines = [
      {
        name: 'Sys',
        data: data.map((item) => item.systolic),
      },
      {
        name: 'Dias',
        data: data.map((item) => item.diastolic),
        lineStyle: {
          color: green,
          width: 1.3,
          type: 'dashed',
        },
      },
    ]

    const dates = data.map((item) => item.date)
    return { lines, dates }
  }, [chartData, timeZone])

  const mobileLegend = {
    data: [
      {
        name: 'Sys',
        icon: 'none',
        textStyle: {
          color: blue,
        },
      },
      {
        name: 'Dias',
        icon: 'none',
        textStyle: {
          color: green,
        },
      },
    ],
  }

  return (
    <EchartsLine
      title="BP Trend"
      legend={{
        ...(isMobile && mobileLegend),
      }}
      xAxis={{
        data: dates,
      }}
      yAxis={{
        show: !isMobile,
        min: 0,
        max: 200,
      }}
      lines={lines}
    />
  )
}

export const HeartRateChart = ({ timeZone, threshold, chartData }) => {
  const data = useMemo(
    () =>
      chartData
        .map((data) => {
          let taken = ''
          if (moment(data.ts).tz(timeZone)) {
            taken = moment(data.ts).tz(timeZone).format('MM/DD/YY')
          }
          return {
            date: taken,
            Pulse: data.pulse,
          }
        })
        .reverse(),
    [chartData, timeZone]
  )
  return (
    <MultiLineChart
      title={'Heart Rate Trend - From BP'}
      label={'Pulse'}
      lines={[
        {
          key: 'Pulse',
          color: blue,
          dot: () => <ThresholdDot threshold={threshold} />,
        },
      ]}
      data={data}
      domain={undefined}
    />
  )
}

export const HeartRateEChart = ({ timeZone, chartData }) => {
  const { isMobile } = useResponseState()
  const { lines, dates } = useMemo(() => {
    const data = chartData
      .map((data) => {
        let taken = ''
        if (moment(data.ts).tz(timeZone)) {
          taken = moment(data.ts).tz(timeZone).format('MM/DD/YY')
        }
        return {
          date: taken,
          pulse: data.pulse,
        }
      })
      .reverse()

    const lines = [
      {
        name: 'Pulse',
        data: data.map((item) => item.pulse),
      },
    ]

    const dates = data.map((item) => item.date)
    return { lines, dates }
  }, [timeZone, chartData])

  const mobileLegend = {
    data: [
      {
        name: 'Pulse',
        icon: 'none',
        textStyle: {
          color: blue,
        },
      },
    ],
  }

  return (
    <EchartsLine
      title={'Heart Rate Trend - From BP'}
      legend={{
        ...(isMobile && mobileLegend),
      }}
      xAxis={{ data: dates }}
      yAxis={{
        show: !isMobile,
        min: 0,
        max: 220,
      }}
      lines={lines}
    />
  )
}
