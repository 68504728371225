import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { CARDIOWELL, TRANSTEK } from '../../common/manufacters'
import { useTimezoneSelect, allTimezones } from 'react-timezone-select'

export const AddPatientForm = ({
  providerID,
  clinic,
  serverMessage,
  addPatient,
}) => {
  const { options: timeZoneOptions } = useTimezoneSelect({
    labelStyle: 'abbrev',
    allTimezones,
  })

  const [patientFirstName, setPatientFirstName] = useState('')
  const [patientLastName, setPatientLastName] = useState('')
  const [patientMRN, setPatientMRN] = useState('')
  const [patientEmail, setPatientEmail] = useState('')
  const [patientPhoneNumber, setPatientPhoneNumber] = useState('')
  const [patientCellNumber, setPatientCellNumber] = useState('')
  const [patientAddress, setPatientAddress] = useState('')
  const [patientCity, setPatientCity] = useState('')
  const [patientState, setPatientState] = useState('')
  const [patientZip, setPatientZip] = useState('')
  const [patientTimeZone, setPatientTimeZone] = useState('')
  const [patientWeightIMEI, setPatientWeightIMEI] = useState('')
  const [patientTTWeightIMEI, setPatientTTWeightIMEI] = useState('')
  const [patientBPIMEI, setPatientBPIMEI] = useState('')
  const [patientTTBpIMEI, setPatientTTBpIMEI] = useState('')
  const [patientGlucoseIMEI, setPatientGlucoseIMEI] = useState('')
  const [patientPulseIMEI, setPatientPulseIMEI] = useState('')
  const [selectedWeightDevice, setSelectedWeightDevice] = useState(CARDIOWELL)
  const [selectedBpDevice, setSelectedBpDevice] = useState(CARDIOWELL)

  const classes = useStyles()

  const displayedBPDeviceIMEI = () => {
    switch (selectedBpDevice) {
      case CARDIOWELL:
        return patientBPIMEI ?? ''
      case TRANSTEK:
        return patientTTBpIMEI ?? ''
      default:
        return ''
    }
  }

  const displayedWeightDeviceIMEI = () => {
    switch (selectedWeightDevice) {
      case CARDIOWELL:
        return patientWeightIMEI ?? ''
      case TRANSTEK:
        return patientTTWeightIMEI ?? ''
      default:
        return ''
    }
  }

  const setDisplayedBpIMEI = (imei) => {
    switch (selectedBpDevice) {
      case CARDIOWELL:
        setPatientBPIMEI(imei)
        return
      case TRANSTEK:
        setPatientTTBpIMEI(imei)
        return
      default:
        return
    }
  }

  const setDisplayedWeightIMEI = (imei) => {
    switch (selectedWeightDevice) {
      case CARDIOWELL:
        setPatientWeightIMEI(imei)
        return
      case TRANSTEK:
        setPatientTTWeightIMEI(imei)
        return
      default:
        return
    }
  }

  return (
    <Typography>
      <Grid container spacing={3}>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            id="name"
            label="First Name"
            name="name"
            autoFocus
            onChange={(event) => {
              setPatientFirstName(event.target.value)
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="name"
            label="Last Name"
            id="name"
            onChange={(event) => setPatientLastName(event.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            id="number"
            label="Medical Record #"
            name="number"
            autoFocus
            onChange={(event) => setPatientMRN(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Phone Number"
            id="number"
            onChange={(event) => setPatientPhoneNumber(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Cell Phone Number"
            id="number"
            onChange={(event) => setPatientCellNumber(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="email"
            label="Email Address"
            onChange={(event) => setPatientEmail(event.target.value)}
          />
          <FormControl
            className={classes.formControl}
            required
            style={{ marginLeft: '15px', marginRight: '0px', width: '40%' }}
          >
            <br></br>
            <InputLabel id="bp-device-select-label">
              Blood Pressure Device
            </InputLabel>
            <Select
              labelId="bp-device-select-label-id"
              id="bp-device-select-id"
              value={selectedBpDevice}
              onChange={(event) => setSelectedBpDevice(event.target.value)}
            >
              <MenuItem value={CARDIOWELL}>Cardiowell</MenuItem>
              <MenuItem value={TRANSTEK}>TT Tele RPM</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Blood Pressure IMEI"
            value={displayedBPDeviceIMEI()}
            onChange={(event) => setDisplayedBpIMEI(event.target.value)}
          />
          <FormControl
            className={classes.formControl}
            required
            style={{ marginLeft: '15px', marginRight: '0px', width: '40%' }}
          >
            <br></br>
            <InputLabel id="ws-device-select-label">Weight Device</InputLabel>
            <Select
              labelId="ws-device-select-label-id"
              id="ws-device-select-id"
              value={selectedWeightDevice}
              onChange={(event) => setSelectedWeightDevice(event.target.value)}
            >
              <MenuItem value={CARDIOWELL}>Cardiowell</MenuItem>
              <MenuItem value={TRANSTEK}>TT Tele RPM</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Weight IMEI"
            value={displayedWeightDeviceIMEI()}
            onChange={(event) => setDisplayedWeightIMEI(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Glucose IMEI"
            onChange={(event) => setPatientGlucoseIMEI(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="number"
            label="Pulse Oximeter IMEI"
            onChange={(event) => setPatientPulseIMEI(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="address"
            label="Address"
            onChange={(event) => setPatientAddress(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="city"
            label="City"
            onChange={(event) => setPatientCity(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="state"
            label="State"
            onChange={(event) => setPatientState(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            style={{ marginLeft: '15px', width: '40%' }}
            name="zip"
            label="Zip"
            onChange={(event) => setPatientZip(event.target.value)}
          />
          <br />
          <FormControl
            className={classes.formControl}
            style={{ marginLeft: '15px' }}
          >
            <br></br>
            <br></br>
            <InputLabel id="demo-simple-select-label">Time Zone</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={(event) => setPatientTimeZone(event.target.value)}
            >
              {timeZoneOptions.map(({ value, label }, i) => (
                <MenuItem key={i} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <p>{serverMessage}</p>
        </form>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submit}
          onClick={() =>
            addPatient({
              patientFirstName: patientFirstName,
              patientLastName: patientLastName,
              patientMRN: patientMRN,
              patientEmail: patientEmail,
              patientPhoneNumber: patientPhoneNumber,
              patientCellNumber: patientCellNumber,
              patientCity: patientCity,
              patientState: patientState,
              patientAddress: patientAddress,
              patientZip: patientZip,
              patientTimeZone: patientTimeZone,
              patientBPIMEI: patientBPIMEI,
              patientTTBpIMEI: patientTTBpIMEI,
              patientWeightIMEI: patientWeightIMEI,
              patientTTWeightIMEI: patientTTWeightIMEI,
              patientGlucoseIMEI: patientGlucoseIMEI,
              patientPulseIMEI: patientPulseIMEI,
              selectedBpDevice,
              selectedWeightDevice,
              patientClinic: clinic,
              providerID: providerID,
            })
          }
        >
          Add Patient
        </Button>
        <div style={{ marginTop: '25px' }}></div>
        <div></div>
      </Grid>
    </Typography>
  )
}
